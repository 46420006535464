import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore"


const firebaseConfig = {
    apiKey: "AIzaSyBOEMAg3mouAG54xaUd-MlUi5NORHCE4wU",
    authDomain: "babylonshishabar-2d5ff.firebaseapp.com",
    projectId: "babylonshishabar-2d5ff",
    storageBucket: "babylonshishabar-2d5ff.appspot.com",
    messagingSenderId: "7916673273",
    appId: "1:7916673273:web:6c19212aef712266aaaa28"
  };

  const app = initializeApp(firebaseConfig);
  export const db = getFirestore(app);