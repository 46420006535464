import './App.css';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc, getDoc} from "firebase/firestore";
import { useEffect, useState,} from "react"
import {Route, Routes} from "react-router-dom"
import Reslist from "./Reslist.js";
import ProductInfo from "./productInfo.js";
import ProductDetail from "./productDetail.js"
import ShishaInfo from "./shishaInfo.js"
import LongdrinksInfo from "./longdrinksInfo.js"
import { Helmet, HelmetProvider } from "react-helmet-async";



function App() {

  return (
    
    <div className="bg-black text-white">
      
      <Routes>
      <Route path="/" element={<Reslist />}/>
     
      <Route path="/:id" element={<ProductInfo />}/>
      <Route path="/:id/:product" element={<ProductDetail />}/>
      <Route path="/94_Shisha" element={<ShishaInfo />}/>
      <Route path="/7_Longdrinks" element={<LongdrinksInfo />}/>

      </Routes>
      <div className="h-screen bg-black flex justify-between p-5 text-yellow-500 font-bold">
      <div><a href="https://firebasestorage.googleapis.com/v0/b/babylonshishabar-2d5ff.appspot.com/o/Impressum.pdf?alt=media&token=1ab64943-d35b-4fc1-ae07-72b12e2fb4a4">Impressum</a></div>
      <div><a href="https://firebasestorage.googleapis.com/v0/b/babylonshishabar-2d5ff.appspot.com/o/rechtliches%2FDATENSCHUTZ.pdf?alt=media&token=4dac4865-7c84-4f25-9fed-d886f35d7190">Datenschutzerklärung</a></div>
      <div><a href="https://firebasestorage.googleapis.com/v0/b/babylonshishabar-2d5ff.appspot.com/o/rechtliches%2FAGB.pdf?alt=media&token=055d2366-2680-45d1-ab86-50351fec6929">AGB's</a></div>
      </div>
    </div>
  );
}

export default App;
